import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import * as Yup from "yup"

// Data
import { themeData } from "../../data/themeData"

// Helpers
import { handleSubmit } from "../../utils/helpers/handleSubmit"

// Styling
import mq from "../../styles/breakpoints"

// Components
import ThemeCheckbox from "./fields/ThemeCheckbox"
import FieldTextarea from "./fields/FieldTextarea"
import FieldError from "./fields/FieldError"
import PageNavigation from "../../components/PageNavigation"

interface ISubject {
  title: string
  name: string
}

interface IProps {
  themeId: string
  themeColor: string
}

const SubjectsForm = ({ themeId, themeColor }: IProps) => {
  const [error, setError] = useState<boolean>(false)

  const generateInitialValues = (subjects: any) => {
    let values = {
      freeInput: "",
    }
    subjects.forEach((subject: ISubject) => {
      values = {
        ...values,
        [subject.name]: false,
      }
    })
    return values
  }

  const generateValidationSchema = (subjects: any) => {
    let schema = {
      freeInput: Yup.string(),
    }
    subjects.forEach((subject: ISubject) => {
      schema = {
        ...schema,
        [subject.name]: Yup.boolean(),
      }
    })
    return schema
  }

  return (
    <Formik
      initialValues={generateInitialValues(themeData[themeId].subjects)}
      validationSchema={Yup.object().shape(
        generateValidationSchema(themeData[themeId].subjects)
      )}
      onSubmit={values => handleSubmit(values, setError)}
    >
      {() => (
        <StyledForm>
          <Container>
            {themeData[themeId].subjects.map((subject: ISubject, i: number) => (
              <StyledThemeCheckbox
                key={i}
                title={subject.title}
                name={subject.name}
                color={themeColor}
              />
            ))}
          </Container>
          <StyledFieldTextarea
            name="freeInput"
            label="Missing a specfic topic? Or any information you wish to share?"
          />
          {error && (
            <StyledFieldError error="Select minimum 1 and maximum 3 sub-topics" />
          )}
          <PageNavigation label="Finish" from="/themes" />
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
`

const StyledThemeCheckbox = styled(ThemeCheckbox)`
  width: 100%;
  margin-bottom: 16px;

  ${mq.from.breakpoint.M`
    width: calc(50% - 12px);
    margin-bottom: 24px;

    :nth-child(odd) {
      margin-right: 12px;
    }

    :nth-child(even) {
      margin-left: 12px;
    }
  `};
`

const StyledFieldTextarea = styled(FieldTextarea)`
  margin-bottom: 32px;
`

const StyledFieldError = styled(FieldError)`
  margin-bottom: 16px;
`

export default SubjectsForm
