import React from "react"
import styled from "styled-components"

// Types
import { IIconType } from "./icons/Icon"

// Styling
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Icon from "./icons/Icon"

interface IProps {
  icon: string
  theme: string
}

const ThemeTitle = ({ icon, theme }: IProps) => (
  <Container>
    <Icon icon={icon as IIconType} width={48} height={48} />
    <Title>{theme}</Title>
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const Title = styled.h3`
  margin: 0 0 0 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  color: ${colors.grey};

  ${mq.from.breakpoint.S`
    font-size: 24px;
  `};
`

export default ThemeTitle
