import React, { useEffect } from "react"
import { navigate } from "gatsby"
import localForage from "localforage"

// Styling
import colors from "../../styles/colors"

// Components
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import ProgressTracker from "../../components/ProgressTracker"
import ThemeTitle from "../../components/ThemeTitle"
import Intro from "../../components/Intro"
import SubjectsForm from "../../components/forms/SubjectsForm"

interface IData {
  role?: string
  organization?: string
  experience?: "yes" | "no"
  theme?: string
  subjects?: string[]
}

const InteractionPatternsPage = () => {
  const redirectWhenNoData = async () => {
    const data = (await localForage.getItem("data")) as IData
    if (
      data === null ||
      !data.role ||
      !data.organization ||
      !data.experience ||
      !data.theme
    ) {
      navigate("/themes")
    }
  }

  useEffect(() => {
    redirectWhenNoData()
  }, [])

  return (
    <Layout>
      <SEO title="Choose a theme" />
      <ProgressTracker currentStep={3} />
      <ThemeTitle icon="interactionPatterns" theme="Interaction Patterns" />
      <Intro
        title="Select your<br/>sub-topic(s)"
        text={[
          "Which of the following, more specific, sub-topics should have the highest priority from your perspective? Select up to 3 sub-topics:",
        ]}
      />
      <SubjectsForm
        themeId="1-interactionPatterns"
        themeColor={colors.themeBlue}
      />
    </Layout>
  )
}

export default InteractionPatternsPage
