import { navigate } from "gatsby"
import localForage from "localforage"

// Firebase
import firebase, { db } from "../../config/firebase"

interface IData {
  role?: string
  organization?: string
  experience?: "yes" | "no"
  theme?: string
  subjects?: string[]
  fakeUsername?: string
}

export const handleSubmit = async (
  values: any,
  setError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const selectedCheckboxes = []
  Object.keys(values).filter(name => {
    if (values[name] === true) {
      selectedCheckboxes.push(name)
    }
  })

  if (selectedCheckboxes.length > 0 && selectedCheckboxes.length < 4) {
    const currentData = await localForage.getItem("data")
    const botDetected = (currentData as IData).fakeUsername !== ""

    const cleanData = () => {
      const currentDataCopy = { ...currentData }
      delete (currentDataCopy as IData).fakeUsername
      return currentDataCopy
    }

    const updatedData = {
      ...cleanData(),
      subjects: selectedCheckboxes,
      freeInput: values.freeInput,
    } as IData

    if (!botDetected) {
      localForage.setItem("data", updatedData, () => {
        db.collection("rawData")
          .doc()
          .set(updatedData)
          .then(() => {
            updatedData.subjects.forEach(subject => {
              db.collection("themes")
                .doc(updatedData.theme)
                .update({
                  [subject]: firebase.firestore.FieldValue.increment(1),
                })
            })
            localForage.clear().then(() => {
              navigate("/thankyou")
              setError(false)
            })
          })
      })
    }
  } else {
    setError(true)
  }
}
