import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Styling
import colors from "../../../styles/colors"

interface IProps {
  name: string
  label: string
  className?: string
}

const FieldSelect = ({ name, label, className }: IProps) => (
  <>
    <Label>{label}</Label>
    <Field as={StyledField} name={name} className={className} />
  </>
)

const Label = styled.label`
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
`

const StyledField = styled.textarea.attrs({
  placeholder: "Share it here...",
})`
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  padding: 24px 48px 24px 24px;
  border-radius: 8px;
  border: none;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.25);
`

export default FieldSelect
