import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Styling
import colors from "../../../styles/colors"
import { fastTransition } from "../../../styles/animations"
import mq from "../../../styles/breakpoints"

// Components
import Icon from "../../icons/Icon"

interface IProps {
  title: string
  name: string
  color: string
  className?: string
}

const ThemeCheckbox = ({ title, name, color, className }: IProps) => (
  <Container className={className}>
    <Field as={StyledField} id={name} name={name} color={color} />
    <Label htmlFor={name}>
      <Title>{title}</Title>
      <StyledIcon icon="check" width={21} height={21} color={colors.white} />
    </Label>
  </Container>
)

const Container = styled.div`
  position: relative;
`

const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px 62px 16px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.25);
  background-color: ${colors.white};

  ${mq.from.breakpoint.S`
    padding: 20px 62px 20px 20px;
  `};

  :after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 28px;
    height: 28px;
    background-color: ${colors.offGrey};
    opacity: 0.25;
    border-radius: 4px;
    transform: translateY(-50%);
    transition: background-color ${fastTransition};
  }
`

const StyledIcon = styled(Icon)`
  z-index: 1;
  display: none;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
`

const StyledField = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;

  :checked + ${Label} {
    ${StyledIcon} {
      display: block;
    }

    :after {
      ${({ color }) => color && `background-color: ${color}`};
      color: ${colors.white};
      opacity: 1;
    }
  }

  :focus + ${Label} {
    outline: 2px solid Highlight;
  }
`

const Title = styled.h2`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  ${mq.from.breakpoint.S`
    font-size: 18px;
    line-height: 26px;
  `};
`

export default ThemeCheckbox
